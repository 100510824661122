import React from 'react';
import logo from '../../svgs/logo.png';
import { CommentsIcon } from '../../svgs/scribble';

export const NavBar = () => {
  return (
    <div className='justify-between items-center px-20 h-10 w-full flex z-[100] sticky'>
      <img src={logo} width={150} height={150}  alt="Logo" />
      <div className='lg:text-white md:text-green-50 flex-row justify-evenly items-center hidden lg:flex'>
        <ul className='flex items-center gap-x-6'>
        <li className='rounded-full px-4 py-2 bg-none text-white cursor-pointer hover:text-blue-600'><a href='#home'>Home</a></li>
          <li className='px-4 py-2 text-white rounded-2xl cursor-pointer hover:text-blue-600'><a href='#services'>Services</a></li>
          <li className='rounded-full px-4 py-2 bg-none text-white cursor-pointer hover:text-blue-600'><a href='#team'>Team</a></li>
          <li className='rounded-full px-4 py-2 bg-none text-white cursor-pointer hover:text-blue-600'><a href='#client'>Client</a></li>
          <li className='rounded-full px-4 py-2 bg-none text-white cursor-pointer hover:text-blue-600'><a href='#contact'>Contact</a></li>
          <li className='flex items-start'>
          <a href="mailto:Hello@zlancreations.com"><div className='border-white border items-center flex p-1 rounded-lg gap-2'>
              <p className='text-white text-sm'>Connect with mail</p>
              <i><CommentsIcon/></i>
          </div>
          </a>
      </li>
        </ul>
        
      </div>
    </div>
  );
};
