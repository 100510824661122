import blurBg from '../../svgs/Mobile/clients-bg.png'
import client1 from '../../svgs/Clients/logo1.png'
import client2 from '../../svgs/Clients/logo2.png'
import client3 from '../../svgs/Clients/logo3.png'
import client4 from '../../svgs/Clients/logo4.png'
import client5 from '../../svgs/Clients/logo5.png'
import client6 from '../../svgs/Clients/logo6.png'
import Aos from 'aos'
export const ClientsMobile = () =>{
    Aos.init()
    
    return(
        <section className="mx-auto overflow-hidden mb-20" id='clients'>
                <h1 className="text-white justify-center flex text-2xl" data-aos="fade-up">Our Clients</h1>
                <div className="justify-center flex mt-10">
                <img src={blurBg} className='relative rounded-2xl blur-md'/>
                <div className='flex flex-col absolute mt-10 gap-5 w-full'>
                <div className='flex justify-evenly items-center gap-5'>
                    <img src={client1} className='w-20 h-20' data-aos="fade-up" />
                    <img src={client2} className='w-20 h-20' data-aos="fade-up" />
                    <img src={client3} className='w-20 h-20' data-aos="fade-up" />
                    </div>
                    <div className='flex justify-evenly  items-center gap-5'>
                    <img src={client4} className='w-20 h-20' data-aos="fade-up" />
                    <img src={client5} className='w-20 h-20' data-aos="fade-up"  />
                    <img src={client6} className='w-20 h-20' data-aos="fade-up"  />
                    </div>
                </div>
            </div>
        </section>
    )
}