import ServiceBanner from '../../svgs/Services-3.png';
import Artistic from '../../svgs/interface-without-color.svg';
import { RightIcon } from '../../svgs/right arrow';
import { FaceSideShade3, ScribbleArc3 } from '../../svgs/scribble';
import { useState, useEffect, useRef } from 'react';
import ArtisticColored from '../../svgs/interface.svg';
import { ServiceButton } from '../Common/services-button';
import "aos/dist/aos.css";
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';

export const ServicesWebsite = () => {
    const [isHover, setIsHover] = useState(false);
    const [isLight,setIsLight] = useState(false);

    const handleHover = () => {
        setIsHover(true);
    }

    const handleLeave = () => {
        setIsHover(false);
    }

    const HandleLight = () =>{
        setIsLight(!isLight);
    }

    const ser1parallax = useParallax<HTMLDivElement>({
        scale: [0.1, 1, 'easeOutQuad'],
        translateY: [200, -200],
        speed:-100
    });
 
 

    return (
        <ParallaxProvider>
            <section className="h-[800px] relative" onMouseEnter={HandleLight} onMouseLeave={HandleLight}>
             <div className={`light13 ${isLight?'block':'hidden'} absolute top-[30vh] right-0 -z-10`}/>
             <div className='container mx-auto'>
            <ServiceButton content="Service | Tres" />
            <div ref={ser1parallax.ref} className='w-[100%] mx-auto h-full z-10 flex justify-center'>
            <div className='w-1/2 hovermove' onMouseOver={handleHover} onMouseLeave={handleLeave}>
                    <div className={`text-white text-5xl absolute z-50 animatemove left-40 top-32 font-semibold font-Avenir tracking-widest`}>
                                <span className="mb-5 gap-x-2 flex">
                                    <h1 data-aos="fade-up" >Make</h1>
                                    <img data-aos="fade-up" src={isHover ? ArtisticColored : Artistic} alt="Artistic" />
                                </span>
                                <h1 className='mb-5' data-aos="fade-up">Clean and</h1>
                                <h1 className='mb-5' data-aos="fade-up">Colorful</h1>
                                <div className='mb-20 service-listing gap-4 flex-col hidden text-sm absolute z-[100]'>
                               <p className='text-white font-poppins'>“A user interface is like a joke. If you have to explain it,
                                it’s not that good” Let us handle your website, app 
                                and AI design, so you can keep your punchlines fresh.
                                </p>
                            </div>
                            </div>
                           
                            <span className={`absolute text-white z-50 left-44 animatemovedown top-80 mt-5 flex flex-col gap-5 text-xl font-Avenir leading-relaxed`}>
                            <p className='hidden border border-white mb-10 -mt-20 px-3 -ml-3 py-1 font-poppins font-medium text-sm rounded-lg'>Website / Application / AI Design</p>
                                <h1 className='cursor-pointer underline-offset-8 underline font-Avenir text-2xl'>UX & UI Design</h1>
                            </span>
                            <img src={ServiceBanner} className={`blur-lg rounded-full banner absolute top-22 left-0 h-[480px] w-[480px] z-0 `} alt="Service Banner"/>
                            <div className={`blur-md full banner scribblefacemove absolute top-7 -left-20 -z-10`}>
                            <FaceSideShade3/>
                            </div>
                          
                            <div className={`blur-md full scribblearc rotate-12 absolute inset-0 top-36 mx-auto left-64 -z-50`}>
                                <ScribbleArc3 />
                            </div>
                        </div>
                    </div>
                </div>

              
            </section>
        </ParallaxProvider>
    )
}