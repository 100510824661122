import ServiceBanner from '../../svgs/Services.png';
import Artistic from '../../svgs/Artistic.svg';
import { RightIcon } from '../../svgs/right arrow';
import { FaceSideShade, ScribbleArc, ServiceOneFaceScribble, Servicesline1, Servicesline1Desktop } from '../../svgs/scribble';
import { useState, useEffect, useRef } from 'react';
import ArtisticColored from '../../svgs/ArtisticColored.svg';
import { ServiceButton } from '../Common/services-button';
import "aos/dist/aos.css";
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';
import React from 'react';

export const ServicesGraphic = () => {
    const [isHover, setIsHover] = useState(false);
    const [isLight,setIsLight] = useState(false);

    const handleHover = () => {
        setIsHover(true);
    }

    const handleLeave = () => {
        setIsHover(false);
    }
    const HandleLight = () =>{
        setIsLight(!isLight);
    }


    const ser1parallax = useParallax<HTMLDivElement>({
        scale: [0.1, 1, 'easeOutQuad'],
        translateY: [200, -200],
        speed:-100
    });
 

    return (
        <ParallaxProvider> 
             <section className="h-[800px] relative" id='services' onMouseEnter={()=>HandleLight()} onMouseLeave={()=>HandleLight()}>
             <div className={`light11 ${isLight?'block':'hidden'} absolute top-[30vh] -z-10 right-0`}/>
             <div className='container mx-auto'>
            <ServiceButton content="Service | Uno" />
            <div ref={ser1parallax.ref} className='w-[100%] mx-auto h-full z-10 flex justify-center' data-aos="fadeup">
            <div className='w-1/2 hovermove' onMouseOver={handleHover} onMouseLeave={handleLeave}>
                    <div className={`text-white text-5xl absolute z-50 animatemove left-40 top-32 font-semibold font-Avenir tracking-widest`}>
                                <span className="mb-5 gap-x-2 flex">
                                    <h1 data-aos="fade-up" >Let Our</h1>
                                    <img data-aos="fade-up" src={isHover ? ArtisticColored : Artistic} alt="Artistic" />
                                </span>
                                <h1 className='mb-5' data-aos="fade-up">Prowess</h1>
                                <h1 className='mb-5' data-aos="fade-up">Run Free</h1>
                                <div className='mb-20 service-listing gap-4 flex-col hidden text-sm absolute z-[100]'>
                                    <p>Want to build a brand and give a creative spin?</p>
                               <p className='text-white font-poppins'>We have got you covered. The Zlan clan is here to craft
                                a strategic roadmap tailored to your unique vision, 
                                delivering impactful solutions that amplify your 
                                brand's presence and captivate your target audience.
                                </p>
                            </div>
                            </div>
                            <span className={`absolute text-white z-50 left-44 animatemovedown  top-80 mt-5 flex flex-col text-xl gap-5 font-Avenir leading-relaxed`}>
                                <p className='hidden border border-white -ml-3 mb-5 px-3 py-1 font-poppins font-medium text-sm rounded-lg'>Brand Strategy / Logo Design / Publishing Design /Animation</p>
                                <h1 className='cursor-pointer underline-offset-8 underline font-Avenir text-xl'>Brand Building & Graphic Design</h1>
                            </span>
                            <img src={ServiceBanner} className={`blur-lg rounded-full banner absolute top-22 left-0 h-[480px] w-[480px] z-0`} alt="Service Banner" />
                           
                            <div className={`blur-md full banner scribblefacemove absolute top-7 -left-20 -z-10`}>
                            <FaceSideShade/>
                            </div>
                          
                            <div className={`blur-md full scribblearc rotate-12 absolute inset-0 top-36 mx-auto left-64 -z-50`}>
                                <ScribbleArc />
                            </div>
                        </div>
                    </div>
                    </div>
                  
             
            </section>
        </ParallaxProvider>
    )
}