import React, { useState, useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Employee1Image from '../../svgs/Manager.png';
import Employee2Image from '../../svgs/Renjith.png';
import Employee3Image from '../../svgs/sreekumar.png';
import Employee4Image from '../../svgs/sri.png';
import Employee5Image from '../../svgs/Manju.png';
import Employee6Image from '../../svgs/Sathish.png';
import Employee7Image from '../../svgs/Ben.png';
import Title from '../../svgs/zlan-clan.png'
import { DecorSide, ScribbleSide, ScribbleTop } from '../../svgs/scribble';
import { useParallax } from 'react-scroll-parallax';

type ImageMapping = {
  Employee1: string;
  Employee2: string;
  Employee3: string;
  Employee4: string;
  Employee5: string;
  Employee6: string;
  Employee7: string;
};



export const Team = () => {



  const [mainImage, setMainImage] = useState<keyof ImageMapping>('Employee1');
  const [managerInfo, setManagerInfo] = useState({
    name: 'MADHAV ',
    role: 'DIRECTOR OF ZLAN / CREATIVE DIRECTOR',
    description:
      'Madhav, the captain of the ship, has the precision of a Swiss watchmaker and the charm of a movie hero. A perfectionist by nature, he wants Zlan to evolve into a space where boring norms get kicked to the curb. ',
  });

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    Aos.init();
  }, []);
  
  const handleImageClick = (imageName: keyof ImageMapping) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId); 
    }

    setMainImage(imageName);
    switch (imageName) {
      case 'Employee2':
        setManagerInfo({
          name: 'RAJEETH',
          role: 'SCRIPT AND CONTENT WRITER',
          description: 'Rajeeth, an avid storyteller, has spawned movie characters and advertisement ideas alike. With an ear for the tales of the world, he spins words like magic, crafting stories that transport us to other worlds.',
        });
        break;
      case 'Employee4':
        setManagerInfo({
          name: 'SREEVIDYA',
          role: 'STRATEGIST',
          description: 'Sreevidya, our strategic powerhouse, is like a superhero of brand building, with a knack for turning small brands into Hulk-sized successes.',
        });
        break;
      case 'Employee3':
        setManagerInfo({
          name: 'SREEKUMAR',
          role: 'DIRECTOR OF ZLAN CREATIONS',
          description: "Sreekumar, a visionary strategist, has the brains of a business tycoon and the heart of a whimsical artist. A pillar of support and a force of energy, he's like a shot of espresso for the Zlan clan.",
        });
        break;
      case 'Employee5':
        setManagerInfo({
          name: 'MANJU',
          role: '',
          description: 'She orchestrates our agency with finesse, seamlessly managing operations and turning chaos into order through her expert skills.',
        });
        break;
      case 'Employee6':
        setManagerInfo({
          name: 'SATHISH',
          role: 'UI/UX DESIGNER',
          description: 'Sathish, the youngest member of Zlan, is always one step ahead. He follows the latest trends in the industry just so he could break them and create new ones like a true rebel.',
        });
        break;
      case 'Employee7':
        setManagerInfo({
          name: 'BEN',
          role: 'GRAPHIC DESIGNER',
          description: "Ben, the design ninja, may move in silence but his ideas scream creativity. He's fun, outgoing, and breaks the rules like it's a sport.",
        });
        break;
      default:
        setManagerInfo({
          name: 'MADHAV',
          role: 'DIRECTOR OF ZLAN / CREATIVE DIRECTOR',
          description: 'Madhav, the captain of the ship, has the precision of a Swiss watchmaker and the charm of a movie hero. A perfectionist by nature, he wants Zlan to evolve into a space where boring norms get kicked to the curb.',
        });
        break;
    }
    
    // Start a new timeout to reset the mainImage and managerInfo states after 6 seconds
    const newTimeoutId = setTimeout(() => {
      setMainImage('Employee1'); 
      setManagerInfo({
        name: 'MADHAV',
        role: 'DIRECTOR OF ZLAN / CREATIVE DIRECTOR',
        description: 'Madhav, the captain of the ship, has the precision of a Swiss watchmaker and the charm of a movie hero. A perfectionist by nature, he wants Zlan to evolve into a space where boring norms get kicked to the curb.',
      });
    }, 6000);
    setTimeoutId(newTimeoutId); 
  };
  
  
  
    
  

  const imageMapping: ImageMapping = {
    Employee1: Employee1Image,
    Employee2: Employee2Image,
    Employee3: Employee3Image,
    Employee4: Employee4Image,
    Employee5: Employee5Image,
    Employee6: Employee6Image,
    Employee7: Employee7Image,
  };

  return (
    <section id="team" className='h-[100vh] mb-20 relative'>
        
      <div className='mx-auto container'>
      <div className="absolute top-0 ml-[160px] -z-10">
                <ScribbleTop/>
        </div>
        <div className="absolute top-[50%] right-0 m-auto">
            <ScribbleSide/>
        </div>
        <div className="absolute bottom-0 right-0 m-auto">
            <DecorSide/>
        </div>
        <div className='absolute inset-0 h-fit m-auto'>
          <div className='flex justify-center flex-col items-center gap-2 relative'>
          <div
         className="light17 absolute -mt-32 w-6 h-6"
                        data-aos="fade-down-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                    />
            <img
              src={imageMapping[mainImage]}
              data-aos='fade-up'
              className='w-[15vw] mt-5 rounded-full'
              id='main'
            />
            <p className='text-white text-3xl' data-aos='fade-up'>
              {managerInfo.name}
            </p>
            <p className='text-white text-lg' data-aos='fade-up'>
              {managerInfo.role}
            </p>
            <p className='text-white text-center w-[350px]' data-aos='fade-up'>
              {managerInfo.description}
            </p>
          </div>
        </div>
        <img
          className='absolute top-[20vh] right-[20vw] w-[7%] rounded-full'
          src={Employee2Image}
          data-aos='fade-up'
          onClick={() => handleImageClick('Employee2')}
        />
        <img
          className='absolute top-[20vh] left-[20vw] w-[7%] rounded-full'
          src={Employee3Image}
          data-aos='fade-up'
          onClick={() => handleImageClick('Employee3')}
        />
        <img
          className='absolute top-[50vh] right-[10vw] w-[7%] rounded-full'
          src={Employee4Image}
          data-aos='fade-up'
          onClick={() => handleImageClick('Employee4')}
        />
        <img
          className='absolute top-[50vh] left-[10vw] w-[7%] rounded-full'
          src={Employee5Image}
          data-aos='fade-up'
          onClick={() => handleImageClick('Employee5')}
        />
        <img
          className='absolute top-[80vh] right-[20vw] w-[7%] rounded-full'
          src={Employee6Image}
          data-aos='fade-up'
          onClick={() => handleImageClick('Employee6')}
        />
        <img
          className='absolute top-[80vh] left-[20vw] w-[7%]'
          src={Employee7Image}
          data-aos='fade-up'
          onClick={() => handleImageClick('Employee7')}
        />  
        <img
          className='absolute inset-0 top-20 mx-auto '
          src={Title}
          data-aos='fade-up'
          onClick={() => handleImageClick('Employee1')}
        />
      </div>
    </section>
  );
};
