import { RightIcon } from "../../svgs/right arrow"
import { InstagramIcon, Instasidebar, LinkedInSideBar, VerticalLine, WhatsAppIcon, WhatsappSideBar } from "../../svgs/scribble"

export const ExploreSite = () => {

    return(
        <div>
            <div className="fixed left-[3vw] top-[30vh] z-[999]">
                <div className="flex flex-col justify-center items-center">
                    <a href="https://www.instagram.com/zlancreations/" target='blank'><Instasidebar/></a>
                    <VerticalLine/>
                    <a href="https://wa.me/919539035376" target='blank'><WhatsappSideBar/></a>
                    <VerticalLine/>
                    <a href="https://www.linkedin.com/company/zlancreationspvtltd" target='blank'><LinkedInSideBar/></a>
                </div>
            </div>
            <a href='#services'>
        <button className="text-white flex items-center justify-center gap-3 m-auto top-80 bottom-0 right-0 left-0 absolute" >Explore Site <RightIcon/> </button>
        </a>
        </div>
    )
}