import { DecorSide, Scribble, ScribbleSide, ScribbleTop } from "../../svgs/scribble"

export const Decors = () => {

    return(
        <>
        <div className="absolute bottom-0">
            <Scribble/>
        </div>
        <div className="absolute top-0 ml-[160px] -z-10">
                <ScribbleTop/>
        </div>
        <div className="absolute top-[50%] right-0 m-auto">
            <ScribbleSide/>
        </div>
        <div className="absolute bottom-10 right-0 m-auto">
            <DecorSide/>
        </div>
            </>

    )
}