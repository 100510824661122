import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import PeopleBg from "../../svgs/people-bg.png";
import People1 from "../../svgs/people/people1.png";
import People2 from "../../svgs/people/people2.png";
import people3 from "../../svgs/people/people3.png";
import { Decors } from "../HomePage/decors";
import { Dotsgroup, SliderArrow, SliderNext } from "../../svgs/scribble";
const testimonials = [
  {
    Company_name: "NSS Matrimony",
    comment: "Working with Zlan was an absolute blast! Their professionalism and  dedication are top-notch. I'm over the moon with  the  results and wholeheartedly recommend their services.Thanks, Zlan, for bringing my vision to life!",
    Client_name: "- Aiswarya Lakshmi P, Managing Director, NSS Matrimony"
  },
  {
    Company_name: "Arthi D",
    comment: "It’s been an incredible Joy to work with Madhav Sreevidya Ben and the whole team at Zlan. They’re meticulous committed and know the design process across many assets. What’s important in working with design teams is the passion and quality of deisgn to the table. Madhav’s team strives for perfection. They’re a league apart",
    Client_name: "Arthi, Strive"
  },
  {
    Company_name: "Ranjini Menon",
    comment: "Associated with Zlan creations on a project,found them to be comfortable to work , the team is truly enterprising,energetic and helpful in communications.Wishing them all the very best.",
    Client_name: "- Ranjini Menon, Owner - Turmerica and media personality"
  },
];

export const WhatPeopleSay = () => {
  const images = [People1, People2, people3]; 

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = images.length;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const previousSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };
  const [isLight,setIsLight] = useState(false);
  const HandleLight = () =>{
    setIsLight(!isLight);
}

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    <section className="container mx-auto h-[700px] relative"   onMouseEnter={()=>HandleLight()} onMouseLeave={()=>HandleLight()} >
       <div
   className={`light19 ${isLight?'block':'hidden'} absolute top-[40vh] right-0 -z-[9999]`}
                    />
   

      <div className="text-white h-auto text-2xl gap-y-3 mb-28 font-jost flex items-center relative justify-center flex-col" data-aos="fade-up">
        <img className="absolute -mt-5 -z-10 mr-40" src={PeopleBg} alt="Background" />
        <h1>What <span className="font-bold"> People </span> say About Our Service</h1>
        <p className="text-[25px] text-[#636364]">Our clients send a bunch of smiles with our service and we love that.</p>
      </div>
      <div className="flex h-72" data-aos="fade-up">
        <div className="w-3/5 ml-20 flex flex-col gap-y-3">
          <h3 className="text-[#A0C0FF] font-jost text-xl">{testimonials[currentIndex].Company_name}</h3>
          <div>
            <p className="text-white font-poppins text-xl w-[90%] mb-10">{testimonials[currentIndex].comment}</p>
            <p className="text-white font-poppins text-xl"> {testimonials[currentIndex].Client_name}</p>
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-y-10">
          <div className="flex gap-32">
            <div className="image-container">
              <img src={images[currentIndex]} alt="Your Image" className="w-40 h-40 object-contain rounded-full" id="main-image" />
            </div>
            <div className="flex items-center">
              <img src={images[(currentIndex + 1) % totalImages]} alt="Your Image" className="w-24 h-24 object-contain rounded-full" />
              <img src={images[(currentIndex + 2) % totalImages]} className="ml-28 w-20 h-20 object-contain rounded-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="text-white flex justify-center gap-40 mt-10 items-center">
        <button onClick={previousSlide} className="border-2 rounded-full p-5 text-4xl animate-pulse">	<SliderArrow/> </button>
        <button className="border-2 rounded-full p-5 text-4xl animate-pulse" onClick={nextSlide}><i className="rotate-180"><SliderNext/></i> </button>
      </div>
    </section>
    </>
  );
};
