import logo from '../../svgs/logo.png'
import serviceBannerTop from '../../svgs/Aura.svg'
import light from '../../svgs/light.png'
import serviceBanner from '../../svgs/Creations.png'
import { CommentsIcon, MailIcon, RighArrowIcon, RightArrowMobile } from '../../svgs/scribble'
import { useState } from 'react'
export const HomePageMobile = () =>{

    const [ismenuopen,setIsMenuOpen] = useState(false)
    const [menustate,setMenuState] = useState(false)


    const MenuOpen = () =>{
        setIsMenuOpen(!ismenuopen)
        setMenuState(true)
    }

    return(
       <section className="overflow-hidden h-screen w-full bg-black p-5" id='home'>
        {menustate &&(
        <div className={`absolute z-50 w-[92%] h-auto ${ismenuopen? 'glass block':'glass-reverse'}`}>
        <div className='py-5 pr-6 pl-4 w-auto'>
        <div className="flex items-center justify-between mb-10">
        <a href="mailto:Hello@zlancreations.com" target='blank'><div className='flex max-w-fit items-center gap-3 bg-[#BCBCBC17] p-2 rounded-xl'>
        <p className='text-white'>Connect with mail</p>
      
        </div>
        </a>
        <div className='flex items-center gap-4' onClick={()=>{MenuOpen()}}>
            <p className='text-white'>Menu</p>
            <div className='w-6 h-6 rounded-full bg-white'></div>
        </div>
        </div>
        <ul className='flex flex-col gap-10 pl-4'>
            <a href='#home' onClick={()=>{MenuOpen()}}>
            <li className='flex justify-between' >
                <p className='text-white'>Home</p>
              
            </li>
            </a>
            <a href='#service' onClick={()=>{MenuOpen()}}>
            <li className='flex justify-between'>
                <p className='text-white'>Services</p>
              
            </li>
            </a>
            <a href='#team-mobile' onClick={()=>{MenuOpen()}}>
            <li className='flex justify-between'>
                <p className='text-white'>Team</p>
              
            </li>
            </a>
            <a href='#clients' onClick={()=>{MenuOpen()}}>
            <li className='flex justify-between'>
                <p className='text-white'>Clients</p>
              
            </li>
            </a>
            <a href ='#contactus' onClick={()=>{MenuOpen()}}>
            <li className='flex justify-between'>
                <p className='text-white'>Contact</p>
             
            </li>
            </a>
        </ul>
        </div>
        </div>)}
        
        <nav className='mx-auto'>
        <div className='flex justify-between z-20 relative h-10 items-center'>
        <div className='flex justify-start items-center'>
        <img src={logo} width={100} height={100} className={`${ismenuopen?'hidden':'block object-contain'}`}/>
        </div>
        <div className={`rounded-full bg-white h-10 p-3 mr-0 gap-2 ${ismenuopen?'hidden':'flex items-center'}`} onClick={()=>{MenuOpen()}}>
            <p>Menu</p>
            <span className='rounded-full w-5 h-5 bg-black'></span>
        </div>
        </div>
        </nav>
        <div className="mx-10 h-full relative">
            <div className='flex flex-col justify-center h-full gap-20 -mt-[8vh] items-center'>
            <img src ={serviceBannerTop} className='z-20 relative' />
            <div>
            <img src={serviceBanner} className='mb-20' />
           <a href='#service'> <div className='flex justify-center items-center gap-4 animate-pulse relative z-20 rounded-full border-[2px] p-3 px-5'>
                <p className='text-white'>Explore Site</p>
                <i><RightArrowMobile/></i>
            </div>
            </a>
            </div>
            <img src={light} className='absolute lightmobilebg lightmobile rounded-[100%] z-10 '/>
            </div>
        </div>
       </section>
    )
}