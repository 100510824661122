import ServiceBanner from '../../svgs/Service-2.png'
import Artistic from '../../svgs/Account.svg'
import { RightIcon } from '../../svgs/right arrow'
import { FaceSideShade2, ScribbleArc2, ServiceOneFaceScribble } from '../../svgs/scribble'
import { useState } from 'react'
import ArtisticColored from '../../svgs/AccountColored.svg'
import { ParallaxProvider, useParallax } from 'react-scroll-parallax'
import { ServiceButton } from '../Common/services-button'




export const ServicesSocialMarketing = () => {

    const [isHover, setIsHover] = useState(false);
    const [isLight,setIsLight] = useState(false);

    const handleHover = () => {
        setIsHover(true);
    }

    const handleLeave = () => {
        setIsHover(false);
    }
    const HandleLight = () =>{
        setIsLight(!isLight);
    }


    const ser1parallax = useParallax<HTMLDivElement>({
        scale: [0.1, 1, 'easeOutQuad'],
        translateY: [200, -200],
        speed:-100
    });
    
    return (
        <ParallaxProvider>
             <section className="h-[800px] relative" onMouseEnter={()=>HandleLight()} onMouseLeave={()=>HandleLight()}>
        <div className={`light12 ${isLight?'block':'hidden'} absolute top-[30vh] left-0 -z-10`}/>
            <div className='container mx-auto'>
            <ServiceButton content="Service | Dos" />
            <div ref={ser1parallax.ref} className='w-[100%] mx-auto h-full z-10 flex justify-center'>
                <div className='w-1/2 hovermove2' onMouseOver={handleHover} onMouseLeave={handleLeave}>
                    <div className={`text-white text-5xl absolute z-50 animatemove2 right-60 top-32 font-semibold font-Avenir tracking-widest`}>
                        <span className="mb-5 gap-x-3 flex">
                            <h1 data-aos="fade-up">Take your</h1>
                            <img data-aos="fade-up" src={isHover ? ArtisticColored : Artistic} alt="Artistic" />
                        </span>
                        <h1 data-aos="fade-up" className='mb-5'>from Blah</h1>
                        <h1 data-aos="fade-up" className='mb-5'>to Bliss</h1>
                        <div className='mb-20 service-listing gap-4 flex-col hidden text-sm absolute z-[100]'>
                               <p className='text-white font-poppins'>Startups, corporates, and entrepreneurs, don't let 
                                    your social media presence go stale.
                                    From curating catchy captions to creating 
                                    drool-worthy content, our social savvy
                                    experts will take your accounts from blah to bliss.
                                </p>
                            </div>
                    </div>
                    <span className={`absolute text-white z-50 right-64 animatemove2down top-80 mt-5 flex flex-col gap-5 text-xl font-Avenir leading-relaxed`}>
                                <p className='hidden border border-white  mb-5 px-3 py-1 font-poppins font-medium text-sm rounded-lg'>Social Media Strategy & Management</p>
                                <h1 className='cursor-pointer underline-offset-8 underline font-Avenir text-xl'>Social Media Marketing & SEO</h1>
                    </span>
                    <img src={ServiceBanner} className={`blur-md rounded-full banner absolute top-22 h-[480px] w-[480px] right-20 z-0`} alt="Service Banner" />
                    <div className={`blur-md full banner scribblefacemove2 absolute top-20 -right-10 -z-10 `}>
                        <FaceSideShade2 />
                    </div>
                    <div className={`blur-md full scribblearc rotate-17 absolute top-8 right-[18%] translate-x-3/4 -z-10 `}>
                            <ScribbleArc2 />
                </div>
                </div>                
            </div>
            </div>
        </section>
        </ParallaxProvider>
    )
}
