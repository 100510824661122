import React, { useRef } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import {  FaceBookIcon, ForwardArrowIcon, InstagramIcon, LinkedInIcon, MailIcon, PhoneIcon, TwitterIcon, WhatsAppIcon } from '../../svgs/scribble';
import maps from '../../svgs/maps.png'
import location from '../../svgs/location.png'
import emailjs from '@emailjs/browser';

export const Contactus = () => {

  const form = useRef<HTMLFormElement | null>(null);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    if (form.current instanceof HTMLFormElement) {
      
      emailjs.sendForm('service_rx7n9bl', 'template_g2qsf9y', form.current, 'f1WgUAqRhJJsfwNVI')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset()
    }
  };

  return (
    <ParallaxProvider>  
      <section id="contact" className="container mx-auto h-auto mb-10 overflow-hidden relative">
        <div className='flex'>
          <div className='w-1/2 mt-[18vh] ml-20'>
            <div className='bg-black w-full h-full'>
              <div className='flex flex-col gap-14'>
              <div className='flex flex-col gap-8'>
                <h1 className='text-white text-6xl'>Contact Us</h1>
                <div className='flex gap-8 items-center justify-start'>
                <i><img src={location} width={25} height={25}/></i>
                <p className='text-white break-words'>HB-47, South Bridge Avenue, Panampilly Nagar, Ernakulam, 
                Kerala, 682036</p>
                </div>
                <div className='flex gap-8 items-center justify-start'>
                <i><MailIcon/></i>
                <p className='text-white'>Hello@zlancreations.com</p>
                </div>
                <div className='flex gap-8 items-center justify-start'>
                <i><PhoneIcon/></i>
                <p className='text-white border-r border-[#636364] pr-5'>+91 62825 65802</p>
                <p className='text-white'>+91 95390 35376</p>
                </div>
            </div>
            <div className='flex flex-col gap-8'>
              <h1 className='text-white text-4xl'>Get in touch</h1>
              <div className='flex gap-8'>
              <form ref={form} onSubmit={sendEmail} className='flex gap-8 w-full'>
                <div className='flex flex-col w-1/2 gap-8'>
                <input placeholder='Enter email ID' required name='Mail_Id' type='email' className='bg-black p-2 border-b-[#636364] text-[#636364] border-b-2 outline-none focus-visible:border-b-2'/>
                <input placeholder='Type Description' required name='message' className='bg-black p-2 border-b-[#636364] text-[#636364] border-b-2 outline-none focus-visible:border-b-2'/>
                </div>
                <button className='flex items-center mt-auto gap-5' type="submit" value="Send">
                <p className='text-white text-xl cursor-pointer'>Send</p>
                <i className='cursor-pointer'><ForwardArrowIcon/></i>
              </button>
              </form>
              </div>
            </div>
            <ul className='flex gap-4'>
              <li><i><FaceBookIcon/></i></li>
              <a href='https://www.linkedin.com/company/zlancreationspvtltd' target='blank'><li><i><LinkedInIcon/></i></li></a>
              <a href="https://wa.me/919539035376" target='blank'><li><i><WhatsAppIcon/></i></li></a>
              <li><i><TwitterIcon/></i></li>
              <a href='https://www.instagram.com/zlancreations/' target='blank'><li><i><InstagramIcon/></i></li></a>
            </ul>
            </div>
            </div>
          </div>
          <div className='w-1/2'>
            <a href='https://goo.gl/maps/A4zwbo98JYAr5mso8' target='blank'>
            <img src={maps}/>
            </a>
          </div>
        </div>
        </section>
    </ParallaxProvider>
  );
};
