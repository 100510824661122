import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonial1 from '../../svgs/people/people1.png'
import testimonial2 from '../../svgs/people/people2.png'
import testimonial3 from '../../svgs/people/people3.png'
import Aos from "aos";
export const TestimonialMobile = () =>{
    var settings = {
        dots: true,
        infinite: true,
        arrows:false,
        autoplay:true,
        centermode:true,
        vertical: false,

        speed: 500,
      };
      Aos.init();
    return(
        <section className="overflow-hidden mb-20 testimonial">
        <div className="flex justify-center">
            <h1 className="text-white text-2xl w-1/2 text-center mb-16" data-aos="fade-left">What People say About Our Service</h1>
        </div>
          <Slider {...settings}  centerMode={true} className='flex justify-center pt-5 gap-10 overflow-hidden' data-aos="fade-up">
            <div className="bg-[#F20065] rounded-2xl p-3 text-sm font-jost  md:h-64 h-96">
                <img src={testimonial1} className="w-20 h-20 rounded-full mb-3"/>
                <h6 className="text-white mb-2">NSS Matrimony</h6>
                <p className="text-white mb-3">Working with Zlan was an absolute blast! Their professionalism and dedication are top-notch. I'm over the moon with the results and wholeheartedly recommend their services.Thanks, Zlan, for bringing my vision to life!
                </p>
                <p className="text-white">- Aiswarya Lakshmi P, Managing Director, NSS Matrimony</p>
            </div>
            <div className="bg-[#29A6FF]  rounded-2xl p-6 text-sm font-jost md:h-64 h-96">
            <img src={testimonial2} className="w-20 h-20 rounded-full mb-3"/>
                <h6 className="text-white mb-2">Arthi D</h6>
                <p className="text-white mb-3">It’s been an incredible Joy to work with Madhav Sreevidya Ben and the whole team at Zlan. They’re meticulous committed and know the design process across many assets. What’s important in working with design teams is the passion and quality of deisgn to the table. Madhav’s team strives for perfection.
</p>
<p className="text-white">-Arthi , Strive</p>
            </div>
            <div className="bg-[#00E874] rounded-2xl p-6 font-jost text-sm md:h-64 h-96">
            <img src={testimonial3} className="w-20 h-20 rounded-full mb-3"/>
                <h6 className="text-white mb-2">Ranjini Menon</h6>
                <p className="text-white mb-3">Associated with Zlan creations on a project,found them to be comfortable to work , the team is truly enterprising,energetic and helpful in communications.Wishing them all the very best.
</p>
<p className="text-white">- Ranjini Menon, Owner - Turmerica and media personality</p>
            </div>
          </Slider>
        </section>
    )
}