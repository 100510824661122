import Aos from 'aos'
import Human from '../../svgs/Human.png'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';


export const QuestionsMobile = () =>{
    const form = useRef<HTMLFormElement | null>(null);
    const sendEmail = (e: React.FormEvent) => {
        e.preventDefault();
        if (form.current instanceof HTMLFormElement) {
          emailjs.sendForm('service_rx7n9bl', 'template_g2qsf9y', form.current, 'f1WgUAqRhJJsfwNVI')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset()
        }
      };


    Aos.init()
    return(
        <section className="flex justify-center mx-8 mb-40">
            <   div className="questionsMobile w-full h-auto max-w-xl rounded-3xl p-8 pb-10 relative">
                <div className='flex'>
            <div className="flex flex-col mb-2" data-aos="fade-up">
            <p className="text-white text-3xl w-4/5 font-jost mb-2" data-aos="fade-up">Have any Questions on Mind?</p>
            <p className="text-white font-jost font-light" data-aos="fade-up">Let’s talk about your business plan</p>
            </div>
            <img src={Human} data-aos="fade-up" className='w-40 h-64 absolute -top-32 right-0'/>
            </div>
            <form className='flex flex-col gap-4 w-full' ref={form} onSubmit={sendEmail}>
            <input placeholder='Type Something ...' type='email' required name='Mail_Id' className='border border-white w-4/5 rounded-lg questionsMobileForm focus-visible:border-white'/>
            <button className='rounded-lg bg-white px-2 w-auto py-3 text-[#28375F] font-bold' type="submit">
                Let's talk
            </button>
            </form>
            </div>
        </section>
    )
}