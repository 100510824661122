import React, { useEffect } from "react";
import HeroBanner from "../../svgs/Creations.png";
import HerobannerTop from "../../svgs/Aura.svg";
import { ExploreSite } from "./exploresite";
import { Decors } from "./decors";


export const BannerImg = () => {
  return (
    
        
        <div className="overflow-auto" >
        <img
        src={HerobannerTop}
        className="absolute m-auto -top-4 left-0 right-[50vw] bottom-44 z-10"
        alt="HerobannerTop"
      />
      <img
        src={HeroBanner}
        className="absolute m-auto inset-0 -z-[1]" width={900} height={175}
        alt="creation"
      />
      
      <div className="overflow-hidden">
        <div className="inset-0 h-full left-24 top-0 absolute flex gap-x-44 z-99 justify-center items-center overflow-hidden">
          <div className="light bg-black h-full w-[100vw] flex flex-1 aboslute z-0 mask"></div>
          <div className="light aboslute w-[20%] h-[40vh] flex bottom-[40vh] flex-0 z-0 opacity-100 light-bg"></div>
          <div className="light bg-black h-full w-[100vw] aboslute flex flex-1 z-99 mask2"></div>
        </div>
      </div>
      <ExploreSite />
      <Decors />
    </div>
 
  );
};
