import { FaceBookIcon, ForwardArrowIcon, InstagramIcon, LinkedInIcon, Locate, MailIcon, PhoneIcon, TwitterIcon, WhatsAppIcon } from "../../svgs/scribble"
import location from '../../svgs/location.png'
import emailjs from '@emailjs/browser';
import { useRef } from "react";
export const ContactMobile = () =>{
const form = useRef<HTMLFormElement | null>(null);

const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    if (form.current instanceof HTMLFormElement) {
      emailjs.sendForm('service_rx7n9bl', 'template_g2qsf9y', form.current, 'f1WgUAqRhJJsfwNVI')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset()
    }
  };


    return(
<section className="overflow-hidden w-full mb-20" id='contactus'>
                <h1 className="text-white text-2xl flex justify-center mb-10">Contact Us</h1>
                <div className="w-full flex justify-center">
                <form className='flex flex-col items-center justify-center gap-8 w-full mb-20' ref={form} onSubmit={sendEmail}>
                <input placeholder='Enter email ID' required name='Mail_Id' type="email" className='bg-black p-2 w-[80%] border-b-[#636364] text-[#636364] border-b-2 outline-none focus-visible:border-b-2'/>
                <input placeholder='Type Description' required name='message' className='bg-black w-[80%] p-2 border-b-[#636364] text-[#636364] border-b-2 outline-none focus-visible:border-b-2'/>
                <button className='flex items-center mt-auto gap-5' type="submit" >
                <p className='text-white text-xl'>Send</p>
                <i><ForwardArrowIcon/></i>
              </button>
              </form>
              </div>
              <div className='flex flex-col gap-8 mb-20 mx-10 p-5 border border-white rounded-2xl'> 
                <div className='flex gap-3 items-center justify-start'>
                <i><MailIcon/></i>
                <p className='text-white'>Hello@zlancreations.com</p>
                </div>
                <div className='flex gap-3 items-center justify-start'>
                <i><PhoneIcon/></i>
                <p className='text-white'>+91 62825 65802</p>
                </div>
                <div className='flex gap-3 items-center justify-start'>
                <i><img src={location} width={25} height={25}/></i>
                <p className='text-white'>Ernakulam, 
Kerala, 682036</p>
                <div className="ml-10">
                <a href='https://goo.gl/maps/A4zwbo98JYAr5mso8' target='blank'><div className="flex justify-center items-center gap-4 bg-[#2B2B2B] p-2 px-3 rounded-md">
                    <p className="text-white font-jost">Locate</p>
                    <i><Locate/></i>
                  </div>
                  </a>
                </div>
                </div>
            </div>
              <ul className='flex gap-4 justify-center'>
              <li><i><FaceBookIcon/></i></li>
              <a href='https://www.linkedin.com/company/zlancreationspvtltd' target='blank'><li><i><LinkedInIcon/></i></li></a>
              <a href="https://wa.me/919539035376" target='blank'><li><i><WhatsAppIcon/></i></li></a>
              <li><i><TwitterIcon/></i></li>
              <a href='https://www.instagram.com/zlancreations/' target='blank'><li><i><InstagramIcon/></i></li></a>
            </ul>
        </section>
    )
}