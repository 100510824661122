import { ServiceButton } from "../Common/services-button"
import Services4 from "../../svgs/Mobile/service-4.png"
import Video from '../../svgs/video-colored.svg';
import { DecorSide, RightArrowMobile, Servicesline1, Servicesline3 } from "../../svgs/scribble"
import Aos from "aos";

export const ServicesWebsiteVideoMobile = () =>{
    Aos.init()
    return(
        <section className="mx-auto overflow-hidden mb-80 relative">
        <ServiceButton content ={'Services | Cuatro'}/>
        <div className="flex justify-center w-full mt-5 mb-8">
        <img src={Services4} data-aos="fade-up"/>
        </div>
        <div className="flex justify-end mb-10">
        <Servicesline1/>
        </div>
<div className="flex flex-col mx-auto w-full">
    <div className="flex justify-center flex-col items-center">
    <div className="text-white text-5xl mx-10">
    <div className="mb-8">
    <p className=" mr-auto flex mb-2" data-aos="fade-up">Make</p><img src={Video} data-aos="fade-up" className="flex mb-2"/><p data-aos="fade-up">From Meh to Wow</p>
    </div>
    <p className="text-white font-jost text-sm w-4/5 mb-6" data-aos="fade-up">If not Oscar worthy, it'd at least be youtube famous! 
Turn your videos from 'meh' to 'wow' with our top 
notch video production team. 'Keep them 
glued to the screens, till the credits roll' is our mantra.
    </p>
    <div  data-aos="fade-up">
    <p className="px-3 py-1 border-white border w-fit text-white text-sm font-poppins mb-4 rounded-lg">Advertising / Line Production / Youtube & Google Ads</p>
    </div>
    <p className="text-lg text-white underline underline-offset-4 flex gap-3 items-center" data-aos="fade-up">Film Production</p>
    </div>
    </div>
</div>
<div className="absolute bottom-20 -right-16">
        <DecorSide/>
    </div>
    </section>

    )
}