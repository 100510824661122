import { useEffect, useState } from 'react';
import './App.css';
import { Clients } from './Components/Clients';
import { HomePage } from './Components/HomePage';
import { Questions } from './Components/Questions';
import { ServicesGraphic } from './Components/Services';
import { ServicesSocialMarketing } from './Components/Services-2';
import { ServicesWebsite } from './Components/Services-3';
import { ServicesVideos } from './Components/Services-4';
import { About } from './Components/about';
import { Contactus} from './Components/contact';


import { Team } from './Components/team';
import { WhatPeopleSay } from './Components/what-people-say';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ServicesGraphicMobile } from './Components/Services/ServiceMobile';
import { ServicesSocialMediaMobile } from './Components/Services-2/ServiceMobile';
import { ServicesWebsiteMobile } from './Components/Services-3/ServiceMobile';
import { ServicesWebsiteVideoMobile } from './Components/Services-4/ServiceMobile';
import { QuestionsMobile } from './Components/Questions/QuestionsMobile';
import { AboutMobile } from './Components/about/aboutMobile';
import { TeamMobile } from './Components/team/TeamMobile';
import { ClientsMobile } from './Components/Clients/Clients';
import { TestimonialMobile } from './Components/what-people-say/TestimonialMobile';
import { ContactMobile } from './Components/contact/contact-us-mobile';
import React from 'react';
import { HomePageMobile } from './Components/HomePage/Home-mobile';

function App() {

  return (
    <>
    <div
    className="lg:block hidden">
      <ParallaxProvider>
    <HomePage/>
   <ServicesGraphic/>
   <ServicesSocialMarketing/>
   <ServicesWebsite/>
   <ServicesVideos/>
   <Questions/>
   <About/>
   <Team/>
   <Clients/>
   <WhatPeopleSay/>
   <Contactus/>
   </ParallaxProvider>
   </div>
   <div
   className="sm:block lg:hidden">
    <HomePageMobile/>
   <ServicesGraphicMobile/>
    <ServicesSocialMediaMobile/>
    <ServicesWebsiteMobile/>
    <ServicesWebsiteVideoMobile/>
    <QuestionsMobile/>
    <AboutMobile/>
    <TeamMobile/>
    <ClientsMobile/>
    <TestimonialMobile/>
    <ContactMobile/>
  </div>
  </>
  );
}
export default App;
