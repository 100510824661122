import React from "react"
import { BannerImg } from "./Banner"
import { NavBar } from "./navbar"

export const HomePage = () =>
{
    return(<section id="home" className="mx-auto py-8 h-[100vh] overflow-hidden">
        <div className='w-[100%] mx-auto fixed z-10'>
        <NavBar/>
        </div>
        <BannerImg/>
        </section>
        )

}